import navTop from '@/components/nav-top'
import noData from '@/components/no-data'
import router from '@/router'

export default {
    install: (vue) => {
        vue.prototype.$loctaionUrl = process.env.NODE_ENV === "development" ? 'https://zdshj.ijidoo.cn/prod-api' : window.location.origin
        vue.prototype.$imgUrl = process.env.NODE_ENV === "development" ? 'https://zdshj.ijidoo.cn/prod-api' : '/prod-api'
        vue.component('navTop', navTop)
        vue.component('noData', noData)
        vue.prototype.toPage=function(item){
            router.push(item)
        }
    }
}
