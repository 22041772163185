<template>
  <div class="no-data-container" style="text-align: center">
    <img class="no-data-img" :src="require('@/assets/images/no-data.png')" />
    <div class="text">数据为空~</div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>
<style lang="less" scoped>
  .no-data-container {
    text-align: center;
    height: 230px;
    position: relative;
  }
  .no-data-img {
    width: 302px;
    height: 100%;
  }
  .text {
    color: #A8B0CF;
    text-align: center;
    font-size: 16px;
    position: absolute;
    bottom: 58px;
    left: 50%;
    transform: translateX(-50%);
  }
</style>
