import axios from "axios"
const instance = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? '' : '/prod-api',
})

const instanceNoToken = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? '' : '/prod-api',
})

// 拦截器
instance.interceptors.request.use(function (config) {
    let userData= {
        userId: localStorage.getItem('userId'),
        operatorId: localStorage.getItem('operatorId'),
        token: localStorage.getItem('token')
    }
    config.headers = {
        ...config.headers,
        ...userData
    }
    return config;
}, function (error) {
    return Promise.reject(error);
})
instance.interceptors.response.use(function (response) {
    /*let token = localStorage.getItem('token')
    if (token) {
        let tokenTime = localStorage.getItem('tokenTime')
        let nowTime = new Date().getTime()
        let cha = parseInt((nowTime - tokenTime) / 1000)
        if (cha > 3 * 24 * 60 * 60) {
            if (location.hash !== '/#/login') {
                window.location = window.location.origin + '/#/login'
            }
            return
        }
    } else {
        if (location.hash !== '/#/login') {
            window.location = window.location.origin + '/#/login'
        }
        return
    }
    */

    if (response.status === 200) {
        if (response.data.code === 403) {
            // localStorage.removeItem('token')
            // localStorage.removeItem('tokenTime')
            if (location.hash !== '/#/login') {
                window.location = window.location.origin + '/#/login'
            }
        }
        if (response.data.code === 200) {
            // localStorage.setItem('tokenTime', new Date().getTime())
        }
    }
    if (response.status === 401) {
        // localStorage.removeItem('token')
        // localStorage.removeItem('tokenTime')
        if (location.hash !== '/#/login') {
            window.location = window.location.origin + '/#/login'
        }
    }
    return response.data;
}, function (error) {
    return Promise.reject(error);
})

// 拦截器
instanceNoToken.interceptors.request.use(function (config) {
    let userData= {
        userId: localStorage.getItem('userId'),
        operatorId: localStorage.getItem('operatorId'),
        token: localStorage.getItem('token')
    }
    config.headers = {
        ...config.headers,
        ...userData
    }
    return config;
}, function (error) {
    return Promise.reject(error);
})
instanceNoToken.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    return Promise.reject(error);
})
export {
    instance,
    instanceNoToken
}
